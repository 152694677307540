import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import { Typography } from '../components/Typography';
import { Box } from '../components/Box';
import { Wrapper } from '../components/Wrapper';
import { Section } from '../components/Section';
import Projects from '../sections/Projects';

import Hero from '../components/Hero';
import { Flex } from '../components/Flex';
import Post from '../components/Post';

import resume from '../../static/Mike_Harvey_resume_2025_pdf.pdf';

const index = ({ data }) => {
  const blogPosts = data.allSanityPost.edges;
  const { site } = data;
  return (
    <Layout>
      <Seo seoData={site.siteMetadata} />
      <Hero css={{
        py: '$6',
        '@sm': {
          py: '$1',
        },
      }}
      >
        <Typography
          css={{
            '@sm': {
              mt: '$4',
            },
          }}
          as="h1"
        >
          Hi, I&apos;m Mike
        </Typography>
        <h2>Software Engineer out of Northern Utah.
          <Typography
            css={{
              display: 'none',
              '@lg': {
                display: 'block',
              },
            }}
            as="h3"
          >
            <Typography
              target="_blank"
              rel="noreferrer"
              href={resume}
              as="a"
              css={{
                fs: '$7',
                color: '$primary',
                textDecoration: 'none',
                borderBottom: '1px solid',
              }}
            >

              My Experience
            </Typography>
          </Typography>

        </h2>
      </Hero>

      <Wrapper name="aboutMe">
        <Section
          css={{
            py: '$7',
            '@sm': {
              py: '$1',
            },
          }}
        >
          <Flex
            mx="auto"
            jc="around"
            ai="center"
            css={{
              '@lg': {
                flexWrap: 'wrap',
              },
            }}
          >
            <Box p="4">
              <StaticImage
                height={500}
                layout="constrained"
                className="mike"
                src="../images/mike-trip.jpg"
                alt="mike on a trip"
              />
            </Box>
            <Box
              py="4"
              css={{
                maxWidth: '$sm',
                '@lg': {
                  maxWidth: 'initial',
                },
              }}
            >
              <Typography as="h2" ta="left">
                About Me
              </Typography>
              <Typography>
                Results-driven Software Engineer with over 8 years of
                experience in front-end development and a strong focus on
                performance optimization and user experience enhancement.
                Proven track record of delivering impactful solutions across
                various industries, utilizing modern web technologies
                including React, Redux, GraphQL, Gatsby, and Contentful.
                Adept at collaborating with cross-functional teams to analyze
                requirements and implement best practices, I have
                successfully revamped legacy systems and built custom CMS
                solutions that streamline processes and improve productivity.
                I thrive in dynamic environments and am always eager to
                tackle new challenges while driving innovation and delivering
                high-quality results.
              </Typography>
            </Box>
          </Flex>
        </Section>
      </Wrapper>

      <Wrapper name="blog">
        <Section
          py="5"
        >
          <Box>
            <Typography
              as="h2"
              css={{
                ta: 'center',
                '@sm': {
                  ta: 'left',
                },
              }}
            >
              Blog
            </Typography>
            <Typography
              css={{
                maxWidth: '$lg',
                width: 'auto',
                margin: 'auto',
                '@md': {
                  maxWidth: '$sm',
                  ta: 'left',
                },
              }}
            >
              I think that a great way to learn is to try and explain the how
              and why when it comes to how certain things were built and the
              decisions behind them. I wanna try and explain the decisions and
              thoughts I have while creating / coding something. I find myself
              in tutorial hell a lot and think this could help me solidify
              that information by explaining on it further. I am not the best
              developer out there, but I want to always try and improve my
              skills and get better.
            </Typography>
          </Box>
          <br />
          {blogPosts.map(({ node }) => {
            const {
              description,
              title,
              date,
              bgColor,
              mainImage,
              slug,
            } = node;

            return (
              <Post
                key={slug.current}
                to={`/${slug.current}`}
                bgColor={`${bgColor}`}
                icon={mainImage.asset.gatsbyImageData.images.fallback.src}
                title={title}
                description={description}
                date={date}
              />
            );
          })}
        </Section>
      </Wrapper>
      <Projects name="projects" />

    </Layout>
  );
};

export default index;

export const query = graphql`
  query homeQuery {
    site {
      siteMetadata {
        title
        description
        author
        url
      }
    }
    allSanityPost {
      edges {
        node {
          id
          title
          slug {
            current
          }
          description
          bgColor
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          _createdAt(formatString: "MM DD YY")
        }
      }
    }
  }
`;
